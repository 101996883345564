const routesHelp = {
    gameType: "/game-type",
    myContest:"/my-contest",
    contest:"/contest/:id",
    contestDetails:"/contest-details/:id",
    teamRegistrations:"/team-registration/:id/:duo",
    howToJoin:'/how-to join',
    subscribePage:'/subscribePage'
    
}
export { routesHelp}