import React, { useEffect, useState } from 'react'
import BasePopUp from './BasePopUp'
import BasePopUp2 from './BasePopUp2'
import SubscribePage from './SubscribePage'
import url from '../constants/url'
import Close from "../assets/images/close.svg";
import { addUserInGameName ,getSubscription2} from '../constants/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext/ThemeContext';
import { useTranslation } from "react-i18next";

//var encodeUrl = require('encodeurl')
const SubscriptionPopup = ({ cancelSubs ,isSubscription,subscriptionData}) => {
    const { t } = useTranslation();
    const [name, setName] = useState('')
    const globalValues = useTheme()
    const [message, setMessage] = useState('')
    const [subscribePage, setSubscribePage] = useState(null)
    const navigate = useNavigate()
    // console.log("^^^^^^^^^^SubscriptionPopup");
    // const showToast = (msg) => {
    //     toast.success(msg)
    // }
    // const onSubmitHandler = async () => {
    //    console.log("onSubmitHandler->SubscriptionPopup")
    // }

    const oncheckSubscription = async () => {
      console.log("globalValues",globalValues)
  //   let body
  //   try{
  //   console.log(globalValues, '@@@@@@@@@buycoins');
  //   if(globalValues?.clientId && globalValues?.clientId==="sfesp" ){
  //     //console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.sfespdemoSubs.svcId,
  //       a:  url.sfespdemoSubs.appId,
  //       p:  url.sfespdemoSubs.packId,
  //       u: globalValues.onmopayU,
  //       url:url.sfespdemoSubs.subscription_url,
  //       clientId:globalValues.clientId,
  //       company: JSON.parse(localStorage.getItem('compnay'))?.code
  //     }
  //   }
  //   else if(globalValues?.clientId && globalValues?.clientId==="BTdemo" ){
  //     console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.BTdemoSubs.svcId,
  //       a:  url.BTdemoSubs.appId,
  //       p:  url.BTdemoSubs.packId,
  //       u: globalValues.onmopayU,
  //       clientId:globalValues.clientId
  //     }
  //   } else if(globalValues?.clientId && globalValues?.clientId==="demo" ){
  //     console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
  //     body = {
  //       svc : url.demoSubs.svcId,
  //       a:  url.demoSubs.appId,
  //       p:  url.demoSubs.packId,
  //       u: globalValues.onmopayU,
  //       clientId:globalValues.clientId
  //     }
  //   }
  //   console.log("^^^^subs body",body)
  //   const res = await getSubscription2(body);
  // console.log("^^^^response",res);
  //   if(res?.data?.param && res?.data?.success){
  //     //  console.log("res?.data?.param",res?.data?.param)
  //       setSubscribePage(res?.data?.param)
  //       navigate("/subscribePage",{state:res?.data?.param})

  //   // return <div dangerouslySetInnerHTML={{ __html: res?.data?.param }} />
  //   }
  //      }catch(err){
  //   console.log("request",err)
  //   setSubscribePage(null)
  //      }
  }
    useEffect(() => {
      console.log("isSubscription",isSubscription,subscriptionData?.status.toLowerCase())
    
      if(subscriptionData?.status.toLowerCase()==="error"){
        setMessage(t("subscription.your_subscription_status_is_in_error"))
      }
      if(subscriptionData?.status.toLowerCase()==="user_blacklisted"){
        setMessage(t("subscription.your_subscription_status_is_user_blacklisted"))
      }
      if(subscriptionData?.status.toLowerCase()==="pending"){
        setMessage(t("subscription.your_subscription_status_is_pending"))
      }
      if(subscriptionData?.status.toLowerCase()==="suspended"){
        setMessage(t("subscription.your_subscription_status_is_suspended"))
      }
      if(subscriptionData?.status.toLowerCase()==="cancelled"){
        setMessage(t("subscription.your_subscription_status_is_cancelled"))
      }
      if(subscriptionData?.status.toLowerCase()==="cancelled"){
        setMessage(t("subscription.your_subscription_status_is_cancelled"))
      }
     
      if(subscriptionData?.status.toLowerCase()==='not_active'){
       // console.log("############################here" ,`https://esports.wineazy.com/sfesp/home?dl=https%3A%2F%2Fonmo-pwa-testing.gamerji.cloud%2Fgame-type%3FgameCode%3D${globalValues.gameCode}`)
        if(globalValues.clientId==="sfesp"){
          setSubscribePage(true)
             //oncheckSubscription()
           //console.log("globalValues",globalValues)
            //window.location.href = `https://esports.wineazy.com/sfesp/home?dl=https%3A%2F%2Fonmo-pwa-testing.gamerji.cloud%2Fgame-type%3FgameCode%3D${globalValues.gameCode}`;
            // var dl=encodeURI(`https%3A%2F%2Fonmo-pwa-testing.gamerji.cloud%2Fgame-type%3FgameCode%3D${globalValues.gameCode}`)
           // var dl=encodeURIComponent(`https://onmo-pwa-testing.gamerji.cloud/game-type?clientId=sfesp&gameCode=${globalValues.gameCode}`)
            var dl=encodeURIComponent(`${url.sfespdemoSubs.link1}${globalValues.gameCode}`)
           // var prurl=encodeURIComponent(`https://esports.wineazy.com/sfesp/home?dl=${dl}`) //old link
            var prurl=encodeURIComponent(`${url.sfespdemoSubs.link2}${dl}`)  //new link
          // console.log("prurl",prurl)
          //  window.location.href = `http://epayh.onmohub.com/subscribe?svc=107&clientId=sfesp&prurl=${prurl}`;
          //  window.parent.location.href = `http://epayh.onmohub.com/subscribe?svc=107&clientId=sfesp&prurl=${prurl}`;
           window.top.location.href = `${url.sfespdemoSubs.link3}${prurl}`;
           console.log("finalLINK",`${url.sfespdemoSubs.link3}${prurl}`)

        }else{
          console.log("here not active",subscribePage)
          setMessage(t("subscription.your_subscription_status_is_not_active"))
        }
       
      }

      if(subscribePage==null){
        console.log("timer on")
        setTimeout(()=>{
         console.log("timer finish")
         if(globalValues.clientId==="sfesp"){
          cancelSubs(false)
         }else{
          cancelSubs(false)
         }
        
       }, 1000*10);
      }
      
      }, [subscriptionData])

    return (
        <>
        {console.log("!subscribePage && subscriptionData?.status.toLowerCase()!=='not_active'",(!subscribePage && subscriptionData?.status.toLowerCase()!=='not_active'))}
        {/* {subscribePage && <SubscribePage data={subscribePage}></SubscribePage>} */}
        {(!subscribePage || subscriptionData?.status.toLowerCase()!=='not_active' || !subscriptionData?.status ||message!='')&& (<BasePopUp2 key={subscriptionData}>

            {/* <h2 className='choose-text' >Subsction</h2> */}
            <div
                className="closeButton"
                onClick={() => cancelSubs(false)}
            >
                {/* <img src={Close} alt="" /> */}
                <div className='close'>X</div>
            </div>
            {/* {
                inNameGame ?
                    <div className='form-group'>
                        <label>{`current ${gameName.toLowerCase()}  name`}</label>
                        <input type="text" placeholder={`${gameName} name`} value={inNameGame} disabled />
                    </div>
                    : ""
            } */}

            {/* <div className='form-group'>
                <input type="text" placeholder={`enter ${gameName.toLowerCase()} username`} onChange={(e) => setName(e.target.value)} value={name} />
            </div> */}
            <div className='sub-pop-div'>
          {/* <h4 style={{margin:"30px", color:"var(--headerText)", fontSize:"medium", textAlign:"center"}} >Please Get Subscription</h4> */}
            <p style={{margin:"30px", color:"var(--headerText)", fontSize:"14px",textAlign:"center"}} >{message}</p>
            </div>
            {/* <button onClick={() => cancelSubs(false)}>Subscribe</button> */}


        </BasePopUp2>)}
        </>
    )
    
}
export default SubscriptionPopup