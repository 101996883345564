import React from 'react'
import Previous from "../assets/images/Header.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import url from '../constants/url';
import BlackArrow from '../assets/images/arrow_black.png'

import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '../ThemeContext/ThemeContext';
const Base = ({ children, title, imageUrl }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const globalvaluesx= useTheme()
  console.log(globalvaluesx.theme)
  const navigateBack = () => {
    console.log("can go back", location);

    if (location.pathname === '/game-type' || location.pathname === '/') {
      if(location.pathname === '/game-type'){
        console.log("can go back with home event", "done");
        localStorage.clear();
        /**below code is use for back to native app from pwa */
        let data = JSON.stringify({ "action": "back" });
        //  if(window.ReactNativeWebView){
        //  window.ReactNativeWebView.postMessage(data);
        window.parent.postMessage(data, "*")
      }else{
        console.log("can go back with back event", "done");
        localStorage.clear();
        /**below code is use for back to native app from pwa */
        let data = JSON.stringify({ "action": "back" });
        //  if(window.ReactNativeWebView){
        //  window.ReactNativeWebView.postMessage(data);
        window.parent.postMessage(data, "*");
      }
     

      //  }

    } else {
      console.log("can go back with back navigate -1", "done");
      navigate(location?.state?.module ? location.state.module === 'team_register' ? -2 : -1 : -1)
    }
  }
  return (
    <div className='wrapper' >
      
    
      <div className="inner-wrap">
        <ToastContainer style={{ position: 'absolute' }} />

        <div className="header" 
        // style={{
        //   position: "absolute", zIndex: "1", width: "100%", display: "flex", background: location.pathname.startsWith("/contest/")
        //     ? "" : "#111622",height:"50px"
        // }}
        >
          <div className="back-btn">
            <a onClick={() => {
              navigateBack()
            }}>
              {
                globalvaluesx.theme == 'option2' ? <img src={BlackArrow} alt="" /> : <img src={Previous} alt="" />
              }
              {/* <img src={Previous} alt="" /> */}
            </a>
          </div>
          {/* <div>{title?.toLowerCase()}</div> */}
          <h6 style={{ background: "", alignSelf: "center", marginTop: 10, }}>{title?.toLowerCase()}</h6>

          <div className="wallet-btn"></div>
        </div>

        <div className="body mCustomScrollbar _mCS_1 ">
          <div
            className="all_sub_screens_div_related_to_ads"
            style={{ display: "inherit", paddingBottom: '50px' }}
          >

            {children}

          </div>
        </div>
      </div>



    </div>
  )
}

export default Base