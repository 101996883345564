import React, { useEffect, useState } from 'react'
import Previous from "../assets/images/Header.png";
import DropDownBlue from "../assets/images/drop_down_blue.png";
import { getContestByGameAndCompanyId, joinViaCode } from '../constants/axios';
import ContestStructure from './ContestStructure';
import NoContests from "../assets/images/Group_8748.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { goback, isLoggedinUser } from './Common';
import Tournament from '../assets/images/tournament-1.jpg'
import { useTheme } from '../ThemeContext/ThemeContext';
import Base from './Base';
import CustomLoader from '../CommonComponent/CustomLoader';
import ShowContest from './ShowContest';
import url from '../constants/url';
import { ToastContainer, toast } from 'react-toastify';
import NotAuthorizedUser from './NotAuthorizedUser';
import Loader from './Loader';
import { Accordion } from 'react-bootstrap';
import DownArrow from '../assets/images/downArrow.png';
import UpArrow from '../assets/images/upArrow.png'
import BlackArrow from '../assets/images/arrow_black.png'
import SubscriptionPopup from './SubscriptionPopup';
import { useTranslation } from "react-i18next";

const Contest = () => {
 // console.log("^^^^^^component load")
  const globalValues = useTheme()
  const { t } = useTranslation();
  const [code, setCode] = useState('')
  const [contestDetails, setContestDetails] = useState([])
  const [showContest, setShowContest] = useState(false)
  const [loader, setLoader] = useState(false);
  const [limitData, setlimitData] = useState(10);
  const [totalPage, setTotalPage] = useState(1)
  const [page, setPage] = useState(1)
  const [applyMsg, setApplyMsg] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const globalvalues = useTheme()
  const [applyData, setApplyData] = useState([])                        
  const [applyCode, setApplyCode] = useState('')
  const [clicked, setClicked] = useState(false)
  const [loadData, setloadData] = useState(false)

  const [showSubscription, setshowSubscription] = useState(false);
   const [isSubscription, setIsSubscription] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState(null)
  // console.log("value>>>>", useTheme())
  // const [clientId, setClientId] = useState('')
  // const [gameCode, setGameCode] = useState('')
  const { id } = useParams()
  // const queryParams = new URLSearchParams(location.search);
  //   console.log(queryParams)
  //   let clientId = ''
  //   let gameCode = ''
  //   if(queryParams.size >0){
  //     queryParams.forEach((key,element) => {
  //       console.log(element,key)
  //       if(element == 'clientId' )
  //        {
  //         clientId = key
  //        }
  //        else if(element == 'gameCode' ){
  //         console.log(key)
  //         gameCode=key
  //        }
  //     })
  //   }
  
  useEffect(() => {
    // console.log("isSubscription",globalvalues.isSubscription,"subscriptionData",globalvalues.subscriptionData)
    setIsSubscription(globalvalues.isSubscription)
    setSubscriptionData(globalvalues.subscriptionData)
   
  }, [globalvalues])
  useEffect(() => {
    // console.log(">>>>isSubscription",isSubscription)
   if(isSubscription===false){
    setshowSubscription(true)
   }else {
    setshowSubscription(false)
   }
  }, [isSubscription])
  const cancelSubs = (status) => {
  //  console.log("%%%%%%%%%%%%%%%%%cancelSubs",status)
    if(!status){
      setshowSubscription(false) 
    let data = JSON.stringify({ "action": "back" });
    console.log("back", data)
    window.parent.postMessage(data, "*");}
    else {
      setshowSubscription(false)
    }
  }

  useEffect(() => {
    console.log("useEffect",loadData)
    if(!loadData){
      fetchContestDetails(page)
    }
   
  },[]
  )
  const onApplyButtonHandler = async () => {
    console.log("clicked****",globalvalues?.companyCode)
    if (applyCode == '') toast.error(t("contest.enter_code"))
    else {
      setLoader(true)
      const body = {
        search: {
          "user": globalvalues?.userId
        }
      }
      try {
        const res = await joinViaCode(body, applyCode, globalvalues?.companyCode)
        // console.log(res?.data?.data)
        if (res?.data?.success) {
          setApplyData(res?.data?.data)
        }
        else {
          toast.error(t("contest.code_not_found"))
        }
        setLoader(false)
        if (res?.data?.data.length == 0) {
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Enter valid code")
          toast.error(t("contest.enter_valid_code"))
        }
        else setShowContest(true)
      }
      catch (error) {
        // console.log(error.response.data)
        // if (error?.response?.data && (!error?.response?.data?.success )){
        
        //   toast.error("Code not found")
        // }else{
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Code request error found")
          toast.error("Enter valid code")
          setTimeout(() => {
            let data = JSON.stringify({ "action": "back" });
            window.parent.postMessage(data, "*");
        }, 3000);
          
       // }
       
      }
      setLoader(false)

    }
  }
  const loadHandler = async () => {
    setLoader(true);
    let p = page + 1
    setPage(p)
    fetchContestDetails(p)

    setLoader(false)

  };
  const fetchContestDetails = async (page) => {
// console.log("^^^^^^^^^^^fetchContestDetails")
setLoader(true)
    let body = {
      "game": globalvalues?.gameCode,//"634a42a89633f3f5b7228455",
      "clientId": globalvalues?.clientId,//"64bbc4e4f94a2cb38047d389",
      "gameType": id,
      "search": {
        "user": globalvalues?.userId
      },
      "pagination": {
        "pageNo": page,
        "recordPerPage": limitData,
        "sortBy": "dateTime",
        "sortDirection": "desc"
      },
      "search": {
        "user": globalvalues?.userId
      }
    }
    try {
      const res = await getContestByGameAndCompanyId(body)
    //  console.log("^^^^^^^^^^^fetchContestDetails",res?.data?.success)
      if (res?.data?.success) {
        setloadData(res?.data?.success)
        let  json_object = {"action": "event" ,"eventName":"Gamerji_GameListVisited", "event_properties":{
          "GameName":res?.data?.data[0]?.game ,
          "ClientId":globalValues?.clientId,
          "Date": new Date() 
        }}
        let data = JSON.stringify(json_object);
        // console.log("Gamerji_GameListVisited------->event ",json_object)
        window.parent.postMessage(data, "*");

        setTotalPage(res?.data?.totalPages)
        setContestDetails([...contestDetails, ...res?.data?.data])
        setLoader(false)
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");

    }
  }
  const createContestSingleBox = (data, index) => {
    return (
      <div className='contest-details'>
        <ContestStructure allData={data} key={index} id={id} />

      </div>)
  };
  const navigateBack = () => {
    console.log("can go back", location);

    if (location.pathname === '/game-type' || location.pathname === '/') {
      console.log("can go back", "done");
      localStorage.clear();
      //dispatch(setOpenExitPopup(true))

      /**below code is use for back to native app from pwa */
      let data = JSON.stringify({ "action": "back" });
      //  if(window.ReactNativeWebView){

      //  window.ReactNativeWebView.postMessage(data);
      window.parent.postMessage(data, "*");

      //  }

    } else {

      navigate(location?.state?.module ? location.state.module === 'team_register' ? -2 : -1 : -1)
    }
  }
  const cancelShowContest = () => {
    setShowContest(false)
  }

  if (loader) return <Loader />//<h2>Loading...............</h2>
  if (!globalvalues?.isLoggedIn) return <NotAuthorizedUser />
  return (
    // <Base imageUrl={location?.state?.img} title={contestDetails.length == 0 ? location.state?.name.toLowerCase() : contestDetails[0]?.gameType?.name.toLowerCase()}>
    <div className='wrapper' >
   
 
   <div className="inner-wrap" style={{padding:'0'}}>
     <ToastContainer style={{ position: 'absolute' }} />

     

     <div className="body mCustomScrollbar _mCS_1 " style={{padding:'0 20px'}}>
    
       <div
         className="all_sub_screens_div_related_to_ads"
         style={{ display: "inherit", paddingBottom: '50px' }}
       >
      {/* {console.log("state", location?.state)} */}
      <>

      <div className='warp' style={{ position: "relative", width: "100%",  marginBottom: 20 }}  >
       
      <div className="header" style={{
       position: "absolute", zIndex: "1", width: "100%", display: "flex", background: location.pathname.startsWith("/contest/")
         ? "" : "#111622",height:"50px", padding:'0'
     }}>
       <div className="back-btn">
         <a onClick={() => {
           navigateBack()
         }}>
           {
                globalvalues.theme == 'option2' ? <img src={BlackArrow} alt="" /> : <img src={Previous} alt="" />
              }
           {/* <img src={Previous} alt="" /> */}
         </a>
       </div>
       {/* <div>{title?.toLowerCase()}</div> */}
       <h6 style={{ background: "", alignSelf: "center", marginTop: 10, }}>{contestDetails.length == 0 ? location.state?.name.toLowerCase() : contestDetails[0]?.gameType?.name.toLowerCase()}</h6>

       <div className="wallet-btn"></div>
     </div>


       <div>
         <div></div>
         <div
           className={

             location.pathname.startsWith("/contest/")
               ? "contest-cover"
               : ""
           }
           style={
             location.pathname.startsWith("/contest/") ?
               { backgroundImage: `url(${url.imageUrl + location?.state?.img})`, height: "300px", }
               : {}
           }

         >

         </div>
         {location.pathname.startsWith("/contest/") ?
           <div className='tournament-image'>
             <img src={url.imageUrl + location?.state?.img} />
           </div> : <></>
         }


       </div>



     </div>
        {/* <div className='gameTypeName'>    <h4>{contestDetails.length ==0 ? location.state?.name.toLowerCase() :  contestDetails[0]?.gameType?.name.toLowerCase()}</h4> </div> */}


        {/* <Accordion className='apply-code'>
              <Accordion.Item eventKey="0">
                <Accordion.Header>join via invite code</Accordion.Header>
                <Accordion.Body>
                <input type='text' placeholder='enter invite code' name='applyCode' onChange={(e) => setApplyCode(e.target.value)} />
                <button onClick={onApplyButtonHandler} style={{width:'100px', borderRadius:'40px ', padding:'5px 0'}}><p 
              style={{marginTop:'-2px',marginBottom:'0', }}
              >apply</p></button>
                </Accordion.Body>
              </Accordion.Item>
              
            </Accordion> */}

        <div className='apply-code'>
          <div className='header'>
            <h6>{t("contest.join_via_invite_code")}</h6>
            {/* <img src={clicked ? UpArrow : DownArrow} onClick={() => setClicked(!clicked)} /> */}
            <div onClick={() => setClicked(!clicked) }>
            {clicked ? 
            
            <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill={`${globalvalues.theme == 'option2' ? "#000" : "#fff"}`}></path> </g></svg>
             : 
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill={`${globalvalues.theme == 'option2' ? "#000" : "#fff"}`}></path> </g></svg>
            }
           </div>

          </div>
          {
            clicked ? <>
              <div className='apply-body'>
                <input type='text' placeholder='enter invite code' name='applyCode' onChange={(e) => setApplyCode(e.target.value)} />
                <button onClick={onApplyButtonHandler} ><p
                  style={{ marginTop: '-2px', marginBottom: '0', }}
                >{t("contest.apply")}</p></button>
              </div>
            </>
              : <></>
          }

        </div>



        {contestDetails?.length > 0 ? (
          contestDetails?.map((item, index) => {
            return (

              createContestSingleBox(item, index)

            )
          })

        ) : (
          // No Contents
          <div className="no_tournament">
              <h3>{t("contest.no_contest_found")}</h3>
          </div>
          // createContestSingleBox()


        )}
        {
          page < totalPage ?
            <div className="padding-top2">
              <button
                style={{ cursor: 'pointer' }}
                onClick={loadHandler}>


{t("contest.load_more")}

              </button>
            </div>
            : ""
        }
      </>

      {showContest && <ShowContest allData={applyData} cancelShowContest={cancelShowContest} />}
      {/* {console.log("showSubscription||||||||||||||||||",showSubscription)} */}
      {showSubscription && globalvalues?.clientId !== 'BTdemo' && 
      // (subscriptionData?.status?.toLowerCase()!=="pending")&& 
      <SubscriptionPopup cancelSubs={cancelSubs} subscriptionData={subscriptionData} isSubscription={isSubscription}/> }
      
     
      
      </div>
        </div>
      </div>



    </div>
  )
}

export default Contest