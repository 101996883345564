import React from 'react'
import Base from './Base'
import { useTranslation } from "react-i18next";

const NotAuthorizedUser = () => {
  const { t } = useTranslation();
  return (
    <div className='wrapper'>
     <div className="inner-wrap">
          
    <div className="header">
      
      <div className="wallet-btn"></div>
    </div>
    <div className="body mCustomScrollbar _mCS_1 ">
 
      
    <h2>{t('not_authorised_user')}</h2>
        

    </div>
  </div>
  </div>
  )
}

export default NotAuthorizedUser