import React, { useEffect, useState } from 'react'
import BasePopUp from './BasePopUp'
import BasePopUp2 from './BasePopUp2'
import url from '../constants/url'
import Close from "../assets/images/close.svg";
import { addUserInGameName ,getSubscription2} from '../constants/axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext/ThemeContext';
import SafeSrcDocIframe from 'react-safe-src-doc-iframe';
const SubscribePage = () => {
  const location=useLocation()
  console.log("^^^^^^****************",location.state)
   // const [data1, setdata1] = useState(null)
  //const htmldata=location.state.toString()
   // useEffect(() => {
    //  setdata1(data)
    // }, [])
    // useEffect(() => {
    // console.log("data1",data1)
    // }, [data1])
    return (
  // <div dangerouslySetInnerHTML={{ __html: location.state }}/>
 
  <iframe srcDoc={location.state} className='iframclass' sandbox="allow-scripts  allow-popups allow-same-origin"></iframe>


     // <iframe src={this.props.src} height={this.props.height} width={this.props.width}/>
    // <SafeSrcDocIframe srcdoc={htmldata} width="100vw" height="100hv" sandbox="allow-scripts" />
      )
      
}
export default SubscribePage