import React from 'react'
const BasePopUp2 = ({children}) => {
  return (
    <div style={{position:'absolute', width:'475px', zIndex:'2'}}>
      
        <div className='add-username' style={{backgroundColor:"#1d1d1d"}}>
        </div>
        <div className='popUp' style={{minHeight:"177px"}}>
            
            {children}
        </div>
    </div>
  )
}

export default BasePopUp2