import React from 'react'
const BasePopUp = ({children}) => {
  return (
    <div style={{position:'absolute', width:'475px', zIndex:'2'}}>
      
        <div className='add-username'>
        </div>
        <div className='popUp'>
            
            {children}
        </div>
    </div>
  )
}

export default BasePopUp