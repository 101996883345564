import React from 'react'
import { Oval } from 'react-loader-spinner';
import Base from './Base';
import { useTheme } from '../ThemeContext/ThemeContext';
function Loader(props) {
  const theme = useTheme()
  return (
    <div className='wrapper'>

  

     <div className="inner-wrap">
      
    <div className="body mCustomScrollbar _mCS_1 " style={{display: "flex", alignItems: "center",justifyContent: "center"}}>
    <div className="loading"></div>
      
    {/* <Oval
                height={80}
                width={80}
                color={theme?.theme == 'option1' ? '#fff' : '#000'}
                wrapperStyle={{position:'absolute', top:'40%', left:'50%', transform:'translate(-50%,-50%)'}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor={theme?.theme == 'option1' ? "#ffffffd1" : '#000000d1'}
                strokeWidth={2}
                strokeWidthSecondary={2}
                className="oval"
            /> */}
        

    </div>
  </div>
  </div>
            
   

  )
}

export default Loader;