import './App.css';
import GameType from './Components/GameType';
import SideBar from './Components/SideBar';
import { Col } from "react-bootstrap";
import { routesHelp } from './config/routesHelp';
import { Route, Routes } from 'react-router-dom';
import MyContest from './Components/MyContest';
import Contest from './Components/Contest';
import ContestDetails from './Components/ContestDetails';
import TeamRegistrationForm from './Components/TeamRegistrationForm';
import HowToJoin from './Components/HowToJoin';
import SubscribePage from './Components/SubscribePage';

function App() {
  return (
    // <div className="wrapper" id="mobile">
    //   <div className='row' style={{display:'flex',height:'100%'}}>

    //   <Col md={5} lg={3}>
    //   <Routes>
    //     <Route path={routesHelp.gameType} element={<GameType />} />
    //     <Route path={routesHelp.myContest} element={<MyContest />} />
    //     <Route path={routesHelp.contest} element={<Contest />} />
    //     <Route path={routesHelp.contestDetails} element={<ContestDetails />} />
    //     <Route path={routesHelp.teamRegistrations} element={<TeamRegistrationForm />} />



    //   </Routes>
    //     </Col>
    //     <Col md={7} lg={9} className="main-banner">
    //       <SideBar />
    //     </Col>
    //   </div>
    //  {/* <div className="row no-gutters">
    //     <Col md={5} lg={3}>
    //       <Routes>

    //         <Route
    //           exact
    //           path={routesHelp.gameType}
    //           element={<GameType/> }
    //         />

    //       </Routes>

    //     </Col>
    //     <Col md={7} lg={9} className="main-banner">
    //       <SideBar />
    //     </Col>
    //   </div> */}
    //   {/* <div className='col-lg-3 col-md-5'>
    //   <Routes>
    //     <Route path={routesHelp.gameType} element={<GameType />} />
    //     <Route path={routesHelp.myContest} element={<MyContest />} />
    //     <Route path={routesHelp.contest} element={<Contest />} />
    //     <Route path={routesHelp.contestDetails} element={<ContestDetails />} />


    //   </Routes>
    //   </div>
    //   <div className='main-banner col-lg-9 col-md-7'>
    //   <SideBar />
    //   </div> */}
    // </div>
    <>
      
        <Routes>
          <Route path={routesHelp.gameType} element={<GameType />} />
          <Route path={routesHelp.myContest} element={<MyContest />} />
          <Route path={routesHelp.contest} element={<Contest />} />
          <Route path={routesHelp.contestDetails} element={<ContestDetails />} />
          <Route path={routesHelp.teamRegistrations} element={<TeamRegistrationForm />} />
          <Route path={routesHelp.howToJoin} element={<HowToJoin />} />
          <Route path={routesHelp.subscribePage} element={<SubscribePage />} />

        </Routes>
    
    </>
  );
}

export default App;
