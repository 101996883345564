import i18next from "i18next";

import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// "Inline" English and Arabic translations.

// We can localize to any language and any number of languages.

// const resources = {

//   en: {

//     translation: lang_eng

//   },

//   ar: {

//     translation: lang_ar

//   },
//   rtl: true,

// };

i18next
.use(i18nBackend)
  .use(initReactI18next)
  .use(LanguageDetector)

  // resources,
  .init({

    fallbackLng: "en",

    // lng:  "en",

    interpolation: {

      escapeValue: false,

    },
    detection:{
      order: [  'localStorage','cookie',   'path',],
      caches:['cookie']
    },
    backend: {
      // loadPath: `${process.env.REACT_APP_LANGUAGE_URL}{{lng}}.json`,
      loadPath: `https://gamerji-uploads.s3.ap-south-1.amazonaws.com/languages/om/staging/{{lng}}.json`,
    },

  });

export default i18next;