const colors = {};

export const btDemo = {
  // white: "black",
  // darkblue: "#479761",
  white: "#0b461e",
  // redx-black:"#111662",
  darkblue: '#111622',
  yellow: "#FEDB00",
  textColor:'#ffffff',
  red: "#EE2737",
  contestDetail:"#2F3043",
  borderColor: '#82838E',
  heading:'radial-gradient(30315.23% 252.57% at 179.25% 26.73%, rgba(47, 48, 67, 0.00) 0%, rgba(30, 31, 47, 0.65) 57.88%, rgba(47, 48, 67, 0.00) 100%)',
  green:'#00954E',
  completed:'#D5D6D9',
  headerText:'#fff',
  blackWhite:'#000',
  popup:'#111622',
  roomId:'#111622',
  roomIdBorder:'#82838E',
  playerDetails:'transparent',
  winning:"#2F3043",
  winningText:'#82838E'
};

export const safariLight = {
  white: "#0b461e",
  // redx-black:"#111662",
  darkblue: '#E9E9E9',
  yellow: "#FEDB00",
  textColor:'#ffffff',
  red: "#43b02a",
  contestDetail:"#fff",
  borderColor: '#82838E',
  heading:'#EFEFEF',
  green:'#00954E',
  completed:'#D5D6D9',
  headerText:'#000',
  blackWhite:'#fff',
  popup:'#fff',
  roomId:'#D6D6D664',
  roomIdBorder:'transparent',
  playerDetails:'#B7B5CB',
  winning:"#D6D6D664",
  winningText:'#000'
  
};

export const safariDark = {
  // white: "black",
  // darkblue: "#479761",
  white: "#0b461e",
  // redx-black:"#111662",
  darkblue: '#000',
  yellow: "#FEDB00",
  textColor:'#ffffff',
  red: "#43b02a",
  contestDetail:"#1d1d1d",
  borderColor: '#82838E',
  heading:'#1B1919',
  green:'#00954E',
  completed:'#D5D6D9',
  headerText:'#fff',
  blackWhite:'#000',
  popup:'#000',
  roomId:'transparent',
  roomIdBorder:'#82838E',
  playerDetails:'transparent',
  winning:"#1d1d1d",
  winningText:'#82838E'
};

export default colors;
