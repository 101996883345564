import React, { useState, useEffect } from 'react'
import { formatAMPM, getProfiledateFormat } from './Common';
import url from '../constants/url';


import Trophy from '../assets/images/Trophy.png'
import ShowWinnersPopup from './ShowWinnersPopup';
import ConfirmationPopUp from './ConfirmationPopUp';
import { getUserInGameName, joinContest } from '../constants/axios';
import { useTheme } from '../ThemeContext/ThemeContext';
import ChangeUsernamePopup from './ChangeUsernamePopup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const ContestStructure = ({ allData, id }) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [showWinners, setShowWinners] = useState(false);
  const [showUserName, setShowUserName] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState(null)
  const { t } = useTranslation();

  const navigate = useNavigate()
  const globalvalues = useTheme()

  useEffect(() => {
    //console.log("isSubscription",globalvalues.isSubscription,"subscriptionData",globalvalues.subscriptionData)
    setIsSubscription(globalvalues.isSubscription)
    setSubscriptionData(globalvalues.subscriptionData)
  }, [globalvalues])

  // console.log(">>> allData", allData)
  const cancelWinner = () => {
    setShowWinners(false)
  }
  const cancelConfirm = () => {
    setShowConfirm(false)
  }
  const cancelUserName = (status) => {
    setShowUserName(false)
    if (status && isSubscription===true){
      onJoinContestHandler()
      setShowConfirm(false)
    }else if(status){
      setShowConfirm(true)
    }

  }

  const onJoinContestHandler = async () => {
    console.log("cs", isSubscription, subscriptionData)
    if (allData?.gameType.players > 1) {
      // let id = allData?.gameType.toLowerCase() === 'squad' ? 2 : 1;
      navigate(`/team-registration/${allData?._id}/${allData?.gameType?.players}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`)
      //window.location.href = `/team-registration/${allData?._id}/${allData?.gameType?.players}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`
    }
    else {
      console.log("******onJoinContestHandler**********",globalvalues?.inNameGame)
      // if(globalvalues?.inNameGame){
        const date = new Date()
        const timeStamp = Date.now()
        // console.log(date)
        const body = {
          "inGameUserName": globalvalues?.inNameGame,
          "user": globalvalues?.userId,
          "contest": allData?._id,
          "joinedDate": moment(new Date()),
          "JoinedTimestamp": timeStamp,
          "clientId": globalvalues?.clientId,
          "isSubscribed": isSubscription
          // "upsTrxId": transactionId // change
        }
        try {
          const res = await joinContest(body)
          if (res?.data?.success) {
            let json_object = {
              "action": "event", "eventName": "Gamerji_Amount_Debit", "event_properties": {
                "Amount": 0,
                "currencyType": allData?.currency[0]?.inCurrency?.name ? "coin" : "coin",
                "GameName": allData?.game,
                "type": "Contest",
                "typeId": allData?._id,
                "status": "Pass",
                "Date": new Date(),
                "userProperty": {
                  "wallet_Balance": globalvalues?.balance?.coinBalance,
                  "ClientId": globalvalues?.clientId,
                  "UserId": globalvalues?.userId
                },
              }
            }
            let data = JSON.stringify(json_object);
            console.log(allData, 'allData------Gamerji_Amount_Debit------', json_object);
            window.parent.postMessage(data, "*");
            let json_object2 = {
              "action": "event", "eventName": "Gamerji_GameRegistered", "event_properties": {
                "GameName": allData?.game,
                "MatchDate": allData?.dateTime,
                "identity": globalvalues?.userId,
                "Date": new Date(),
                "ClientId": globalvalues?.clientId
  
              }
            }
            console.log("json data2-------------", json_object2)
            let data2 = JSON.stringify(json_object2);
            window.parent.postMessage(data2, "*");
            navigate(`/contest-details/${allData?._id}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`)
            //window.location.href = `/contest-details/${allData?._id}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`
          }else if(!res){
            toast.error(t('contest.cant_find_ingamename_try_agin'))
            navigate(`/game-type?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`)
          }
        } catch (error) {
          console.log("___2____ERR", error.response)
          if (error?.response?.data?.data[0]?.msg)
            toast.error(error.response.data.data[0].msg)
          let data = JSON.stringify({ "action": "back" });
          console.log("back", data)
          window.parent.postMessage(data, "*");
        }
      // }else{
      //     toast.error("inGameName Not Found : try Again")
      //     let data = JSON.stringify({ "action": "back" });
      //     console.log("back", data)
      //     window.parent.postMessage(data, "*");
      //   }
      }
    

  }

  const getjoin = async () => {
    console.log("clicked")
    console.log(globalvalues?.inNameGame)
    if (!globalvalues?.inNameGame) {
      setShowUserName(true)
    }
    else if(globalvalues?.inNameGame && isSubscription===true){ 
      onJoinContestHandler()
      setShowConfirm(false)
    }else{

    
      // if(allData?.gameType?.players >=2 ){
      //   let id = allData?.gameType?.players;
      //   if(allData?.gameType?.isExtraPlayers){
      //     id += 1
      //   }
      //   window.location.href = `/team-registration/${allData?._id}/${id}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`
      // }
      setShowConfirm(true)
    }
  }

  const getWinnerClick = () => {
    setShowWinners(true);

  };
  return (
    <>
      <div className='first'>
        <div className='box'>
          <h3>{t("contest.date")}</h3>
          <h2>{getProfiledateFormat(allData?.dateTime)}</h2>
        </div>
        <div className='box'>
          <h3>{t("contest.time")}</h3>
          <h2>{formatAMPM(new Date(allData?.dateTime))}</h2>
        </div>
        {/* <div className='box'>
          <h3>{" "}</h3>
          <h2>{" "}</h2>
        </div> */}

        <div className='box'>
          <h3>{t("contest.id")}</h3>
          <h2>{allData?.shortCode}</h2>
        </div>
      </div>
      <div className='first'>

        {allData?.titles?.slice(0, 2).map((itemInner, index) => {
          if (itemInner?.name !== "-" && itemInner?.isSelection) {
            return (
              <div className='box' key={index}>
                <h3> {itemInner?.name.toLowerCase()}{" "}</h3>
                <h2>{itemInner?.value.toLowerCase()}</h2>
              </div>
            );
          }
        })}
        <div className='box'>
          <h3>{allData?.winningModel === "perRank" ? "winners" : "per kill"}{" "} 
          {allData?.winningModel === "perRank" ? 
          <span>
          <svg  height="10px" width="10px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#FFFFFF" stroke-width="1.5"></circle> <path d="M12 17V11" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#FFFFFF"></circle> </g></svg>
          </span>:
          <></>}
          </h3>
          <h2>
            {
              allData?.winningModel === "perRank" ?
                <b
                  className="pointer"
                  onClick={() => getWinnerClick(allData)}

                >
                  {" "}

                  {/* <img src={Trophy} /> */}
                  {/* {console.log("theme",globalvalues.theme)} */}
                  {/* <span >
                    <svg height="12px" width="12px" fill={`${globalvalues.theme === "option2" ? "#000" : "#fff"}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21,4H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H3A1,1,0,0,0,2,5V8a4,4,0,0,0,4,4H7.54A6,6,0,0,0,11,13.91V16H10a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19a3,3,0,0,0-3-3H13V13.91A6,6,0,0,0,16.46,12H18a4,4,0,0,0,4-4V5A1,1,0,0,0,21,4ZM6,10A2,2,0,0,1,4,8V6H6V8a6,6,0,0,0,.35,2Zm8,8a1,1,0,0,1,1,1v1H9V19a1,1,0,0,1,1-1ZM16,8A4,4,0,0,1,8,8V4h8Zm4,0a2,2,0,0,1-2,2h-.35A6,6,0,0,0,18,8V6h2Z"></path></g></svg>
                  </span> */}

                  {" "}{allData?.totalWinners}
                </b>
                :
                <b className="" >
                  {((allData?.currency?.[0]?.outCurrency?.code ==
                    "INR" || allData?.currency?.[0]?.outCurrency?.code == 'inr') ? (
                    "₹"
                  ) : (<img
                    src={
                      url.imageUrl +
                      allData?.currency?.[0]?.outCurrency?.img?.default}
                    alt=""
                    className="coin_img"
                  />))}
                  {" "}
                  {allData?.killPoints}{" "}
                </b>
            }
          </h2>
        </div>
        <div className='box'>
          <h3>{t("contest.prize_pool")}</h3>
          <h2>
            {((allData?.currency?.[0]?.outCurrency?.code === "INR" || allData?.currency?.[0]?.outCurrency?.code == 'inr') ? (
              "₹"
            ) : (<img
              src={
                url.imageUrl +
                allData?.currency?.[0]?.outCurrency?.img?.default
              }
              alt=""
              className="coin_img"
            />))}
            {" "}
            {allData?.prizePool}</h2>
        </div>
      </div>
      <div className='second'>
        <div style={{ display: 'flex', height: '100%' }}> <h2 style={{ marginRight: '10px' }}>{allData?.totalJoinedPlayers}/{allData?.totalPlayers}</h2> <h3>{t("contest.enteries_submitted")}</h3></div>
        <div className='inner' style={{ marginTop: '4px' }}>
          <span style={{ width: `${100 * allData?.totalJoinedPlayers / allData?.totalPlayers}%`, color: 'yellow' }}></span>
        </div>

      </div>
      <div className='second' style={{ padding: 0 }}>
        {

          allData?.isJoined === true ? <button onClick={() => navigate(`/contest-details/${allData?._id}`)}><p>{t("contest.joined")}</p></button> :
            allData?.totalJoinedPlayers >=
              allData?.totalPlayers ?
              <button className=''><p>{t("contest.full")}</p></button> :
              <button onClick={getjoin}>
                {allData?.entryFee > 0 ? (
                  <p> {t("contest.play_for")} {"  "}
                    {((allData?.currency[0]?.inCurrency?.code == "INR" || allData?.currency[0]?.inCurrency?.code == 'inr')) ? "₹" :
                      <img
                        style={{ margin: '0 5px' }}
                        src={
                          url.imageUrl +
                          allData?.currency[0]?.inCurrency?.img?.default
                        }
                        alt=""
                        className="coin_img"
                      />
                    }
                    {/* )} */}
                    {"  "}
                    {allData?.entryFee}
                  </p>
                ) : (
                  <p>{t("contest.play_for_free")}</p>
                )}
              </button>
        }

      </div>



      {showWinners == true && (
        <ShowWinnersPopup allWinners={allData} cancelWinner={cancelWinner} />
      )}
      {showConfirm == true && (
        <ConfirmationPopUp allData={allData} cancelConfirm={cancelConfirm} id={id} />
      )}
      {
        (showUserName === true) &&
        <ChangeUsernamePopup cancelUserName={cancelUserName} gameName={allData?.game} inNameGame={globalvalues?.inNameGame} gameCode={globalvalues?.gameCode} userId={globalvalues?.userId} changeInNameGame={globalvalues.changeInNameGame} />
      }
    </>
  )
}

export default ContestStructure