import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router  } from "react-router-dom";
import { ThemeProvider } from "./ThemeContext/ThemeContext";
import './constants/i18n.js'
import Loader from './Components/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<Loader />}>
    <Router>
    < ThemeProvider>
        <App />
        </ThemeProvider>
    </Router>
    </Suspense>
  // </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
