import React, { useEffect, useState } from 'react'
import BasePopUp from './BasePopUp'
import Close from "../assets/images/close.svg";
import { addUserInGameName } from '../constants/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ChangeUsernamePopup = ({ cancelUserName, gameName, inNameGame, gameCode, userId, changeInNameGame }) => {
    const [name, setName] = useState('')
    const { t } = useTranslation();

    const navigate = useNavigate()
  


    console.log("globalvalues?.inNameGame", inNameGame);

    const showToast = (msg) => {
        toast.success(msg)
    }
    const onSubmitHandler = async () => {
        if (name == '') toast.error(t('user_in_game_name_popup.please_add_name'))
        else {
            const body = {
                user: userId,
                game: gameCode,
                userINGameName: name
            }
            try {
                const res = await addUserInGameName(body)
                console.log(res)
                if(res===false){
                   toast.error(t('user_in_game_name_popup.add_ingame_name_failed_check_company_code'))
                   console.log("addUserInGameName request failed ",res)
                   navigate(-1) 
                }else{
                    changeInNameGame(name)
                    localStorage.setItem('inNameGame', name)
                    cancelUserName(true)
                    showToast(t('user_in_game_name_popup.name_updated_successfully'))
                }
            } catch (error) {
                toast.error(t('user_in_game_name_popup.add_ingame_name_failed_check_company_code'))
                console.log("error",error)
                let data = JSON.stringify({ "action": "back" });
                window.parent.postMessage(data, "*");
            }
        }
    }
    return (
        <BasePopUp>

            <h2 className='choose-text' >{t('user_in_game_name_popup.add_change_user_in_game_name', {gamename:gameName.toLowerCase()})}</h2>
            <div
                className="closeButton"
                onClick={() => cancelUserName(false)}
            >
                {/* <img src={Close} alt="" /> */}
                <div className='close'>X</div>
            </div>
            {
                inNameGame ?
                    <div className='form-group'>
                        <label>{t('user_in_game_name_popup.current_game_name', {gameName:gameName.toLowerCase()})}</label>
                        <input type="text" placeholder={t('user_in_game_name_popup.game_name', {gameName:gameName})} value={inNameGame} disabled />
                    </div>
                    : ""
            }

            <div className='form-group'>
                <input type="text" placeholder={t('user_in_game_name_popup.enter_username', {gameName:gameName.toLowerCase()})} onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <button onClick={onSubmitHandler}>{t('user_in_game_name_popup.submit')}</button>


        </BasePopUp>
    )
}
export default ChangeUsernamePopup