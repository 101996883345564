import React from 'react'
import BasePopUp from './BasePopUp'
import ContestStructure from './ContestStructure'
import Close from "../assets/images/close.svg";
const ShowContest = ({allData,cancelShowContest}) => {
   // console.log(allData)
  return (
    <BasePopUp>
    
      <h2 className='h2'>{allData?.title}</h2>
            <div
                className="closeButton"
                onClick={() =>cancelShowContest()
                }
              >
                {/* <img src={Close} alt="" /> */}
                <div className='close'>X</div>
              </div>
    <div className='contest-details'>

        <ContestStructure allData={allData} />
    </div>
    </BasePopUp>
  )
}

export default ShowContest